import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import _ from 'lodash'

import { Layout } from '../components/common'
import { MetaData } from '../components/common/meta'
import { tags as tagsHelper } from '@tryghost/helpers'

// Bootstrap
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons'

/**
* Single post view (/:slug)
*
* This file renders a single post and loads all the content.
*
*/
const Post = ({ data, location }) => {
    const post = data.ghostPost

    const publicTags = _.map(tagsHelper(post, { visibility: `public`, fn: tag => tag }), `slug`)
    const primaryTag = publicTags[0] || ``
    const tagLink = "/tag/" + primaryTag
    // tagLink = tagLink.toLowerCase()
    return (
        <>
            <MetaData
                data={data}
                location={location}
                type="article"
            />
            <Helmet>
                <style type="text/css">{`${post.codeinjection_styles}`}</style>
            </Helmet>
            <Layout>
                <Container>
                    <article className="content">
                        <Row>
                            <Col>
                                { post.feature_image ?
                                    <figure className="post-feature-image">
                                        <img src={ post.feature_image } alt={ post.title } />
                                    </figure> : null }
                            </Col>
                        </Row>
                        <Row>
                            <Col s={`auto`} md={2} ></Col>
                            <Col>
                                <section className="post-full-content">
                                    <h1 className="content-title">{post.title}</h1>

                                    {/* The main post content */ }
                                    <section
                                        className="content-body load-external-scripts"
                                        style={{ fontSize: `1.5em`}}
                                        dangerouslySetInnerHTML={{ __html: post.html }}
                                    />
                                </section>
                            </Col>
                            <Col s={`auto`} md={2} ></Col>
                        </Row>
                        <Row>
                            <Col>
                                <Link className="tag-link" to={tagLink}>
                                    More {primaryTag.charAt(0).toUpperCase() + primaryTag.slice(1)}
                                    &nbsp;<FontAwesomeIcon icon={faAngleDoubleRight} />
                                </Link>
                            </Col>
                        </Row>
                    </article>
                </Container>
            </Layout>
        </>
    )
}

Post.propTypes = {
    data: PropTypes.shape({
        ghostPost: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
}

export default Post

export const postQuery = graphql`
    query($slug: String!) {
        ghostPost(slug: { eq: $slug }) {
            ...GhostPostFields
        }
    }
`
